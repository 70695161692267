<template>
  <div>
    <!-- 大屏首页 -->
    <section class="section p0" style="padding-bottom: 0">
      <div class="col-lg-12 p0 container_max">
        <!-- <video
          autoplay
          muted
          loop
          playsInline="{true}"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-playsinline="true"
          x5-video-orientation="portraint"
          style="width: 100%; height: 100%; object-fit: cover"
          src="https://mooxun.oss-cn-hangzhou.aliyuncs.com/%EF%BC%AD%EF%BC%B0%EF%BC%94/WeChat_20240808095302.mp4"
        ></video> -->
        <img src="@/assets/images/home/home_top_max.png" style="width: 100%; height: 100%; object-fit: fill" alt="" />
        <div class="center_box flex-cc">
          <h1 class="T64px_Bold colorfff">CONG.FU</h1>
          <span class="T20px_Regular colorfff text_personal">Personal Real Estate Corporation</span>
          <span class="T20px_Regular colorfff zh_text_personal">傅聪个人房地产公司</span>
        </div>
        <img class="max_right_bottom wow animate__fadeInLeft" data-wow-duration="1s"
          src="@/assets/images/home/right_bottom.png" alt="" />

        <div class="start_bottom_box flex-cc wow animate__fadeInUp" data-wow-duration="2s">
          <span class="T20px_Regular colorfff">开始你的旅程</span>
          <img class="start_bottom_icon" src="../../assets/images/home/start_bottom_i.png" alt="" />
        </div>
      </div>
    </section>
    <!-- 关于我们 -->
    <section ref="search" class="  section about_my">
      <div class="container flex-w">
        <div class="col-lg-7">
          <h1 class="
              T64px_Bold
              colorfff
              about_my_title
              flex-cc
              wow
              animate__fadeInUp
            " data-wow-duration=".5s">
            关于我们
          </h1>
          <div class="colorfff tips_box">
            <span class="T16px_Regular oen_span wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="100">
              我们致力于为 <span class="colorA6B565"> 温哥华及中国地区 </span>
              的客户提供专业、全面且贴心的房产租赁与管理服务。作为温哥华领先的房地产公司，我们拥有一支经验丰富的团队，专注于满足房东和租客的
              <span class="colorA6B565"> 多样化需求 </span>
              。无论是寻找理想租房、提供专业的租赁管理，还是协助房东优化投资回报，我们都始终秉持着
              <span class="colorA6B565"> “专业、高效、便捷、安心” </span>
              的服务宗旨，为客户提供 <span class="colorA6B565"> 一站式 </span>
              的支持与保障。
            </span>
            <br />
            <span class="T16px_Regular oen_span wow animate__fadeInUp" data-wow-duration="2.5s" data-wow-offset="100">
              我们通过科学的管理流程和创新的服务模式，为每一位客户量身打造最优的解决方案，
              让您的租房体验更加轻松和愉快。无论您是房东还是租客，我们都将成为您最值得信赖的合作伙伴，
              帮助您在温哥华这个美丽的城市找到属于自己的家。
            </span>
          </div>
        </div>
        <div class="col-lg-5 flex-wr">
          <img class="About_us_right_logo" src="@/assets/images/home/About_us_right.png" alt="" />
        </div>

        <div class="col-lg-12 about_list flex-wb">
          <div class="col-lg-3 flex-cc about_item">
            <span class="T20px_Regular colorfff">专业团队</span>
            <div class="color534545 about_item_child">
              <span class="T64px_Regular">10</span>
              <span class="T36px_Regular">如一日</span>
            </div>
          </div>
          <div class="col-lg-3 flex-cc about_item">
            <span class="T20px_Regular colorfff">高效便捷</span>
            <div class="color534545 about_item_child">
              <span class="T64px_Regular">24/7</span>
              <span class="T36px_Regular">服务流程</span>
            </div>
          </div>
          <div class="col-lg-3 flex-cc about_item">
            <span class="T20px_Regular colorfff">安全把控</span>
            <div class="color534545 about_item_child">
              <span class="T36px_Regular">极低出租风险</span>
            </div>
          </div>
          <div class="col-lg-3 flex-cc about_item">
            <span class="T20px_Regular colorfff">财务透明</span>
            <div class="color534545 about_item_child">
              <span class="T36px_Regular">详细房屋背调</span>
            </div>
          </div>
        </div>

        <div class="col-lg-12 service_list flex-wb">
          <div class="service_item flex-cc" v-for="(item, index) in MyService" :key="index">
            <img :src="item.icon" :style="`width:${item.w};height:${item.h};`" :width="item.w" :height="item.h"
              alt="" />

            <div class="flex-cc service_item_text">
              <span class="T20px_Regular colorA6B565 title">{{
            item.title
          }}</span>
              <span class="T18px_Regular">{{ item.tips }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 全部房子 -->
    <section class=" all_house panel">
      <div class="container flex-w">
        <div class="col-lg-12 house_title_bj flex-cc">
          <img class="chong_icon" src="@/assets/images/home/chong.png" alt="" />
          <span class="T24px_Regular color000 title">梦居之旅</span>
          <span class="T16px_Regular color616161">
            不清楚租房方向？不妨从你的工作或学校区域开始吧
          </span>
        </div>
        <div class="col-lg-12 flex-w" style="padding-top: 9rem">
          <div class="screen_box flex-w">
            <div class="screen_btn_box" :class="`dropbtn${index}`" v-for="(item, index) in screenList" :key="index">
              <button class="screen_btn flex-wb animation_right">
                <span class="screen_btn_title T14px_Regular">
                  {{ item.title }}
                </span>
                <img :src="item.icon" :class="`screen_icon${index}`" class="screen_btn_icon" alt="" />
              </button>
              <div class="dropdown-content T14px_Regular">
                <a @click="
            parameterChange(
              item.paramNmae,
              items.value,
              items.label,
              index
            )
            " v-for="items in item.chil" :key="items.value">
                  {{ items.label }}
                </a>
              </div>
            </div>
          </div>
          <div class="screen_box flex-w">
            <button @click="getHouseList" class="search_btn T14px_Regular colorfff">
              立即搜索
            </button>
            <!-- <button class="free_btn T14px_Regular color000">自由探索</button> -->
          </div>
        </div>

        <div class="house_info_list">
          <header class="T12px_Regular color828282">
            当前搜索{{ total }}个结果
          </header>

          <div class="house_list flex-w">
            <div class="house_list_item" v-for="(item, index) in list" :key="index">
              <div class="img_box">
                <img class="house_logo" :src="item.photo[0]" alt="" />
                <button @click="infoRouter(item.id)" class="T14px_Regular no_show_btn colorfff">
                  查看详情
                </button>
                <div class="label_box colorfff flex-wc">
                  <span class="money T14px_Regular">$3000</span>
                  <span class="xian"></span>
                  <span class="unit T14px_Regular">月</span>
                </div>
              </div>
              <div class="flex-c info_box">
                <span class="T12px_Regular colorA6B565">优质房源</span>
                <span class="T24px_Regular color333 name">{{ item.name }}</span>
                <span class="T16px_Regular color333 area">{{ item.size }} sqft/总面积</span>
              </div>
              <div class="flex-w footer_box">
                <div class="col-lg-6 footer_info_box flex-w">
                  <img class="info_icon" src="@/assets/images/home/brd.png" alt="" />
                  <span class="T16px_Regular color333">{{ item.parking }} Bed</span>
                </div>
                <div class="col-lg-6 footer_info_box flex-w">
                  <img class="info_icon" src="@/assets/images/home/bathroom.png" alt="" />
                  <span class="T16px_Regular color333">{{ item.toilet }} Bathroom</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 精品 -->
    <section class="section hardcover ">
      <div class="hardcover_container flex-w">
        <header class="col-lg-12 col-12 header_box flex-w">
          <div class="col-lg-6 col-6 flex-c count_box">
            <span class="T48px_Regular color000">精选房源</span>
            <span class="T18px_Regular colorA6B565 tips_w">优质房源一站汇集，找到您理想家园，开启美好新生活</span>
          </div>
          <div class="col-lg-3 col-3 flex-we">
            <div>
              <button class="T20px_Regular hardcover_sx_btn animation_right"
                :class="item.value == goodParam.type ? 'act' : ''" v-for="item in realEstateTypeList" :key="item.key"
                @click="screenHardcover(item)">
                {{ item.label }}
              </button>
            </div>
          </div>
        </header>

        <div class="list_box col-lg-12 col-12 flex-w">

          <div class="col-lg-4 col-4 flex-c list_item_box item_right_imag" v-for="(item) in goodList" :key="item.id">

            <div class="col-12 flex-cc">
              <div class="fangz_image_box">
                <img :src="item.photo[0]" class="fangz_logo" alt="" />
                <template v-for="items in realEstateTypeList">
                  <span v-if="items.value == item.type" :key="items.value" class="T14px_Regular label_info colorfff">{{
            items.label }}</span>
                </template>
                <button @click="infoRouter(item.id)" class="T14px_Regular no_show_btn colorfff">
                  查看详情
                </button>
              </div>
            </div>


            <div class="col-12 flex-c fangz_image_info">
              <span class="T12px_Regular colorA8B565">优质房源</span>
              <span class="T24px_Regular color333 name">{{ item.name }}</span>
              <span class="T16px_Regular color333 area">{{ item.size }} sqft/总面积</span>
            </div>
            <div class="flex-w footer_box">
              <div class="col-lg-6 footer_info_box flex-w">
                <img class="info_icon" src="@/assets/images/home/brd.png" alt="" />
                <span class="T16px_Regular color333">{{ item.parking }} Bed</span>
              </div>
              <div class="col-lg-6 footer_info_box flex-w">
                <img class="info_icon" src="@/assets/images/home/bathroom.png" alt="" />
                <span class="T16px_Regular color333">{{ item.toilet }} Bathroom</span>
              </div>
            </div>

          </div>
        </div>

        <div class="pagination_box flex-wc" v-show="goodList.length">
          <button class="previous_btn T18px_Regular" @click="pagingHardcover(goodParam.page - 1)">
            上一页
          </button>
          <button class="number_btn T18px_Regular" @click="pagingHardcover(item)"
            :class="item == goodParam.page ? 'act' : ''" v-for="item in goodParam.maxPage" :key="item">
            {{ item }}
          </button>
          <button class="net_btn T18px_Regular" @click="pagingHardcover(goodParam.page + 1)">
            下一页
          </button>
        </div>
        <div class="col-12 flex-wc gengduo_box">
          <button class="T20px_Regular w170px_btn_h gengduo_btn animation_middle before_line">
            更多房源
          </button>
        </div>
      </div>
    </section>

    <section class="section cong_box">
      <div class="container flex-wc">
        <h1 class="T64px_Bold colorfff cong_title wow animate__fadeInDown">
          CONG.FU
        </h1>
        <div class="cong_text_box flex-c">
          <img src="../../assets/images/home/cong_text.png" class="cong_text_image" alt="" />
          <button class="
              T20px_Regular
              colorfff
              list_btn
              w170px_btn_h
              animation_middle_lfetRig
            " @click="infoRouter(item.id)">
            与我联系
          </button>
        </div>
        <img class="chong_bj_logo" src="@/assets/images/home/chong_bj_b.png" alt="" />
      </div>
    </section>

    <section class="section type_box">
      <div class="col-lg-12 flex-wc">
        <div class="col-lg-4 new_footer_box" @click="routerBtn('/hire')">
          <img src="../../assets/images/home/new_footer1.png" alt="" />
          <span class="T20px_Regular colorfff title">出租房产</span>
        </div>
        <div class="col-lg-4 new_footer_box" @click="routerBtn('/residence')">
          <img src="../../assets/images/home/new_footer2.png" alt="" />
          <span class="T20px_Regular colorfff title">买卖房产</span>
        </div>
        <div class="col-lg-4 new_footer_box" @click="routerBtn('/business')">
          <img src="../../assets/images/home/new_footer3.png" alt="" />
          <span class="T20px_Regular colorfff title">商业房产</span>
        </div>
      </div>
    </section>

    <section class="section card_box">
      <div class="container flex-wc">
        <img class="footer_img_logo" src="@/assets/images/home/footer_img.png" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { houseList, dictInfo } from "@/api/api.js";
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";


ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);
export default {
  data() {
    return {
      MyService: [
        {
          icon: require("../../assets/images/home/f1.png"),
          title: "专车接送",
          tips: "安排专车实地考察",
          w: "2.0938rem",
          h: "2.0938rem",
        },
        {
          icon: require("../../assets/images/home/f2.png"),
          title: "房源筛选+申请",
          tips: "根据需求预算筛选合适房源",
          w: "2.3125rem",
          h: "2.3125rem",
        },
        {
          icon: require("../../assets/images/home/f3.png"),
          title: "安排入住",
          tips: "确认钥匙交接，设施安全",
          w: "2.25rem",
          h: "2.25rem",
        },
        {
          icon: require("../../assets/images/home/f4.png"),
          title: "在线看房",
          tips: "高清视频讲解，无地点限制",
          w: "2.4688rem",
          h: "2.4688rem",
        },
        {
          icon: require("../../assets/images/home/f5.png"),
          title: "入住检查",
          tips: "提供房屋检查报告",
          w: "2.2188rem",
          h: "2.2188rem",
        },
      ],
      screenList: [
        {
          icon: require("@/assets/images/home/s1.png"),
          title: "地点",
          paramNmae: "region",
          chil: [],
        },
        {
          icon: require("@/assets/images/home/s2.png"),
          title: "地产类型",
          paramNmae: "type",
          chil: [],
        },
        {
          icon: require("@/assets/images/home/s2.png"),
          title: "房子类型",
          paramNmae: "house_type",
          chil: [],
        },
        {
          icon: require("@/assets/images/home/s3.png"),
          title: "交易类型",
          paramNmae: "pay_type",
          chil: [],
        },
        {
          icon: require("@/assets/images/home/s4.png"),
          title: "房产尺寸",
          paramNmae: "size",
          chil: [],
        },
        {
          icon: require("@/assets/images/home/s5.png"),
          title: "地产年限",
          chil: [],
        },
        {
          icon: require("@/assets/images/home/s6.png"),
          title: "预算",
          chil: [],
        },
      ],
      hardcoverList: [
        {
          img: require("@/assets/images/home/one.png"),
          name: "5363 Arentwood",
          arr: "Vancouver, BC",
          text: "这套位于市中心的现代化公寓设计时尚，采光充足。宽敞的开放式布局，配备高端厨房电器和精美装修，为您提供舒适的都市生活体验。周边交通便利，购物、餐饮一应俱全，适合年轻专业人士或小家庭居住。",
        },
        {
          img: require("@/assets/images/home/two.png"),
          name: "5222 Rgwood",
          arr: "Vancouver, BC",
          text: "这套位于市中心的现代化公寓设计时尚，采光充足。宽敞的开放式布局，配备高端厨房电器和精美装修，为您提供舒适的都市生活体验。周边交通便利，购物、餐饮一应俱全，适合年轻专业人士或小家庭居住。",
        },
        {
          img: require("@/assets/images/home/one.png"),
          name: "5363 Arentwood",
          arr: "Vancouver, BC",
          text: "这套位于市中心的现代化公寓设计时尚，采光充足。宽敞的开放式布局，配备高端厨房电器和精美装修，为您提供舒适的都市生活体验。周边交通便利，购物、餐饮一应俱全，适合年轻专业人士或小家庭居住。",
        },
        {
          img: require("@/assets/images/home/two.png"),
          name: "5222 Rgwood",
          arr: "Vancouver, BC",
          text: "这套位于市中心的现代化公寓设计时尚，采光充足。宽敞的开放式布局，配备高端厨房电器和精美装修，为您提供舒适的都市生活体验。周边交通便利，购物、餐饮一应俱全，适合年轻专业人士或小家庭居住。",
        },
      ],
      list: [],
      total: 0,
      pages: 100,
      currentPage: 5,
      goodTotal: 0,
      goodParam: {
        pageSize: 6,
        page: 1,
        maxPage: 1,
      },

      param: {
        pageSize: 4,
        page: 1,
        maxPage: 1,
      },
      goodList: [],

      //地点
      locationList: [],
      //地产类型
      realEstateTypeList: [],
      //房子类型
      houseTypeList: [],
      //交易类型
      payTypeList: [],
      //房产尺寸
      propertySizeList: [],
      //地产年限
      realEstateTenureList: [],
      //预算
      budgetList: [],
    };
  },
  created() {
    this.getHouseList();

    this.getDictList("locationNmae", "locationList", 0);
    this.getDictList("real_estate_type", "realEstateTypeList", 1);
    this.getDictList("house_type", "houseTypeList", 2);
    this.getDictList("pay_type", "payTypeList", 3);
    this.getDictList("property_size", "propertySizeList", 4);
    this.getDictList("real_estate_tenure", "realEstateTenureList", 5);
    this.getDictList("budget", "budgetList", 6);
  },
  mounted() {
  
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
  methods: {
    /**
 * 滑动页面效果初始化
 */
    slideInit() {
      gsap.registerPlugin(ScrollTrigger);
      let panels = gsap.utils.toArray(".panel");
      panels.map((panel) =>
        ScrollTrigger.create({ trigger: panel, start: "top top" })
      );
      panels.forEach((panel) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : "bottom bottom",
          pin: true,
          pinSpacing: false,
        });
      });
    },
    /**
     * 筛选
     * @param {*} paramNmae 参数名称
     * @param {*} value 搜索值
     * @param {*} label
     * @param {*} index
     */
    parameterChange(paramNmae, value, label, index) {
      console.log(paramNmae, value, label, index);
      this.param[paramNmae] = value;
      this.screenList[index].title = label;
    },
    /**
     * 获取字典接口
     * @param {String} name 字典名称标题
     * @param {String} pramsName 赋值名称
     * @param {String} fatherIndex 父级赋值位置
     */
    getDictList(name, pramsName, fatherIndex) {
      dictInfo({ name: name }).then((res) => {
        this[pramsName] = res.data;
        if (name == "real_estate_type") {
          this.goodParam.type = res.data[0].value;
          this.getGoodHouseList();
        }
        if (fatherIndex || fatherIndex == 0) {
          this.screenList[fatherIndex].chil = res.data;
          console.log(this.screenList);
        }
      });
    },
    /**
     *精品房源分页
     @param {Nuber} page 分页
     */
    pagingHardcover(page) {
      if (page && this.goodParam.page != page && this.goodParam.maxPage >= page) {
        this.goodParam.page = page;
        this.getGoodHouseList();
      }
    },
    /**
     * 精品房源赛选
     * @param {Obj} e 当前选中房源类型信息
     */
    screenHardcover(e) {
      this.goodParam = {
        pageSize: 6,
        page: 1,
        type: e.value,
      };
      this.getGoodHouseList();
    },
    getHouseList() {
      houseList(this.param).then((res) => {
        this.list = res.list;
        this.total = res.total;

        this.$nextTick(()=>{
            this.slideInit()
        })
      });
    },
    /**
     * 获取精品房源
     * @param {Obj} e 当前选中房源类型信息
     */
    getGoodHouseList() {
      houseList(this.goodParam).then((res) => {
        this.goodList = res.list;
        this.goodTotal = res.total;
        this.goodParam.maxPage = res.maxPage;
      });
    },
    /**
     * 跳转详情页面
     * @param { * } id 房源id
    */
    infoRouter(id) {
      this.$router.push({
        path: "houseInfo",
        query: { id: id },
      });
    },
    /**
    * 跳转路由信息
    * @param { * } url 跳转路由地址
   */
    routerBtn(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.container_max {
  position: relative;
  height: 100vh;

  .center_box {
    position: absolute;
    // width: 15.0313rem;
    height: 10.5625rem;
    background-image: url("../../assets/images/home/home_bj.png");
    background-size: 10.625rem 10.5625rem;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .text_personal,
    .zh_text_personal {
      letter-spacing: 0.1563rem;
      height: 0.75rem;
    }

    .zh_text_personal {
      margin-top: 0.5rem;
    }
  }

  .max_right_bottom {
    position: absolute;
    width: 7.2813rem;
    height: 2.3438rem;
    right: 3.75rem;
    bottom: 1.875rem;
  }

  .start_bottom_box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    .start_bottom_icon {
      width: 0.875rem;
      height: 3.3438rem;
      margin-top: 0.9375rem;
    }
  }
}

.about_my {
  background: #292323;
  padding-top: 2.1875rem;
  padding-bottom: 7.9375rem;

  .container {
    align-items: flex-end;
    position: relative;
  }

  .about_my_title {
    height: 4.875rem;
    background-image: url("../../assets/images/home/About_us.png");
    background-size: 18.6875rem 3.125rem;
    background-repeat: no-repeat;
    background-position-y: bottom;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .tips_box {
    margin-top: 1.875rem;
    // width: 23.9063rem;
    line-height: 1.0625rem;
    display: inline-block;

    .oen_span {
      padding-bottom: 0.9375rem;
      display: inline-block;
    }
  }

  .About_us_right_logo {
    width: 15.1563rem;
    height: 12.875rem;
    background: #d9d9d9;
  }

  .about_list {
    margin-top: 2.4375rem;

    .about_item_child {
      margin-top: 0.3125rem;
      height: 2.625rem;
      display: flex;
      align-items: flex-end;

      .T64px_Regular {
        height: 2.625rem;
      }
    }
  }

  .service_list {
    position: absolute;
    height: 8.375rem;
    background: #ffffff;
    box-shadow: 0px 0.125rem 0.125rem 0px rgba(195, 195, 195, 0.25);
    border-radius: 0.2188rem 0.2188rem 0.2188rem 0.2188rem;
    bottom: -10.875rem;
    z-index: 2;

    .service_item {
      width: 20%;
      position: relative;

      &:nth-child(1)::before {
        border: 0.0156rem solid #fff;
      }

      &::before {
        content: "";
        position: absolute;
        height: 1.6563rem;
        border: 0.0156rem solid #bdbdbd;
        left: 0;
      }

      .service_item_text {
        margin-top: 0.625rem;

        .title {
          height: 1.4063rem;
          line-height: 1.4063rem;
        }
      }
    }
  }
}

.all_house {
  padding-top: 5.3125rem;
  padding-bottom: 2.1875rem;
  background: #f0f2ee;
  position: relative;
  min-height: 120vh;

  .house_title_bj {
    position: absolute;
    margin: 0 auto;
    top: 5.3125rem;
    left: 0;
    right: 0;
    width: 13.625rem;
    height: 10.5625rem;
    background-image: url("../../assets/images/home/chong_bj.png");
    background-size: 10.625rem 10.5625rem;
    background-position: center;
    background-repeat: no-repeat;

    .title {
      height: 1.5625rem;
      margin-top: 0.8125rem;
    }
  }

  .screen_box {
    position: relative;

    // display: inline-block;
    .dropbtn0:hover .dropdown-content {
      display: block;
      transition: all 0.5s;
    }

    .dropbtn1:hover .dropdown-content {
      display: block;
      transition: all 0.5s;
    }

    .dropbtn2:hover .dropdown-content {
      display: block;
      transition: all 0.5s;
    }

    .dropbtn3:hover .dropdown-content {
      display: block;
      transition: all 0.5s;
    }

    .dropbtn4:hover .dropdown-content {
      display: block;
      transition: all 0.5s;
    }

    .dropbtn5:hover .dropdown-content {
      display: block;
      transition: all 0.5s;
    }

    .dropbtn6:hover .dropdown-content {
      display: block;
      transition: all 0.5s;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 4.7rem;
      box-shadow: 0px 0.25rem 0.5rem 0px rgba(0, 0, 0, 0.2);
      top: 1.5rem;
      z-index: 99;
      transition: all 0.5s;
    }

    .dropdown-content a {
      color: black;
      padding: 0.375rem 0.5rem;
      text-decoration: none;
      display: block;
    }

    .dropdown-content a:hover {
      background-color: #f1f1f1;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    .screen_btn_box {
      margin-left: 0.5313rem;

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .screen_btn {
      width: 4.7188rem;
      height: 1.6875rem;
      margin-left: 0.5313rem;
      border-radius: 0.0625rem;
      border: 0.0313rem solid #828282;
      background: transparent;
      z-index: 1;

      .screen_btn_title {
        width: 2.8125rem;
        margin-left: 0.4688rem;
        white-space: nowrap; //不换行
        overflow: hidden; //超出部分隐藏
        text-overflow: ellipsis; //文本溢出显示省略号
      }

      .screen_btn_icon {
        margin-right: 0.4688rem;
      }

      .screen_icon0 {
        width: 0.5469rem;
        height: 0.625rem;
      }

      .screen_icon1 {
        width: 0.6094rem;
        height: 0.5781rem;
      }

      .screen_icon2 {
        width: 0.7969rem;
        height: 0.625rem;
      }

      .screen_icon3,
      .screen_icon4,
      .screen_icon6,
      .screen_icon5 {
        width: 0.5938rem;
        height: 0.625rem;
      }

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .search_btn {
      width: 4.0625rem;
      height: 1.6875rem;
      background: #232020;
      border-radius: 0.0625rem;
      border: 0.0313rem solid #000000;
      margin-left: 0.8rem;
      // margin-right: 0.5313rem;
    }

    .free_btn {
      width: 4.0625rem;
      height: 1.6875rem;
      border-radius: 0.0625rem;
      background: transparent;
      border: 0.0313rem solid #000000;
    }
  }

  .house_info_list {
    margin-top: 1.4375rem;

    .house_list {
      margin-top: 0.3125rem;

      .house_list_item {
        width: 13.0938rem;
        overflow: hidden;
        margin-right: 0.9375rem;
        margin-bottom: 0.625rem;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img_box {
          position: relative;
          width: 13.0938rem;
          height: 8.0625rem;
          overflow: hidden;

          .house_logo {
            width: 13.0938rem;
            height: 8.0625rem;
            transition: all 0.5s;
          }

          .no_show_btn {
            position: absolute;
            left: -9.375rem;
            bottom: 0;
            width: 4.0625rem;
            height: 1.6875rem;
            background: #232020;
            border-radius: 0.0625rem;
            border: none;
            transition: all 0.5s;
          }

          .label_box {
            position: absolute;
            top: 0.4063rem;
            right: 0.4688rem;
            width: 4.0625rem;
            height: 1.125rem;
            background: #a6b565;

            .xian {
              width: 0.0313rem;
              height: 0.3438rem;
              background: #fff;
              margin: 0 0.4688rem;
            }
          }
        }

        .info_box {
          padding: 0.8125rem 1.2188rem 1.125rem 1.2188rem;
          border: 0.0313rem solid #d9d9d9;

          .name {
            width: 100%;
            height: 1.1875rem;
            line-height: 1.1875rem;
            margin: 0.25rem 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .footer_box {
          border: 0.0313rem solid #d9d9d9;
          height: 1.5625rem;
          border-top: none;

          .footer_info_box {
            padding-left: 1.25rem;
            position: relative;

            &:nth-child(1)::before {
              content: "";
              position: absolute;
              height: 0.7188rem;
              width: 0.0313rem;
              background: #e0e0e0;
              right: 0;
            }

            .info_icon {
              margin-right: 0.375rem;
            }
          }
        }

        &:hover {
          .no_show_btn {
            left: 0;
            transition: all 0.5s;
          }

          .house_logo {
            transform: scale(1.2);
            transition: all 0.5s;
          }
        }
      }
    }
  }
}

.hardcover {
  padding-top: 1.7813rem;
  padding-bottom: 1.5625rem;
  min-height: 100vh;
  background-color: #fff;

  .hardcover_container {
    padding: 0 1.4063rem;
  }

  .header_box {
    padding-bottom: 1.25rem;
    border-bottom: 0.0313rem solid #bdbdbd;
    justify-content: flex-end;

    .count_box {
      // text-align: center;
      justify-content: center;
      align-items: center;

      .tips_w {
        // width: 10.125rem;
      }
    }


    .hardcover_sx_btn {
      position: relative;
      z-index: 1;
      // width: 2.0625rem;
      padding: 0 0.4375rem;
      height: 1.5938rem;
      border-radius: 0.0625rem;
      border: 0.0313rem solid #333333;
      background: transparent;
      margin-right: 0.625rem;

      &:hover {
        color: #fff;
        border-color: #fff;
        transition: all 0.5s;
      }

      &.act {
        background: #a6b565;
        color: #ffffff;
        border: 0.0313rem solid #a6b565;
      }
    }

    .before_line {
      margin-left: 1.7813rem;

      &::before {
        content: "";
        position: absolute;
        width: 2.125rem;
        height: 0px;
        border: 0.0313rem solid #333333;
        bottom: -0.5625rem;
        right: 0;
        transition: all 1s;
      }

      &:hover:before {
        transition: all 1s;
        background: #a6b565;
        border: 0.0313rem solid #a6b565;
      }
    }
  }

  .list_box {
    padding-top: 1.7813rem;

    .list_item_box {
      // height: 16.125rem;
      margin-right: .75rem;
      width: calc(33.333333% - .50rem);
      margin-bottom: .625rem ;

      &:nth-child(3n) {
        width: calc(33.333333% - .50rem);
        margin-right: 0;
      }

      .address {
        height: 0.9063rem;
        margin-bottom: 0.6875rem;
      }

      .tips {
        width: 17.125rem;
        min-height: 3.1875rem;
      }

      .list_btn {
        margin-top: 0.5313rem;
      }

      .fangz_image_box {
        width: 100%;
        height: 9.875rem;
        position: relative;
        overflow: hidden;

        .fangz_logo {
          width: 100%;
          height: 9.875rem;
          transform: scale(1);
          transition: all 0.5s;
        }

        .label_info {
          position: absolute;
          width: 3.4375rem;
          height: 1.125rem;
          background: #a6b565;
          text-align: center;
          line-height: 1.125rem;
          top: .5313rem;
          right: .625rem;
        }

        .no_show_btn {
          position: absolute;
          left: -9.375rem;
          bottom: 0;
          width: 4.0625rem;
          height: 1.6875rem;
          background: #232020;
          border-radius: 0.0625rem;
          border: none;
          transition: all 0.5s;
        }
      }


      .fangz_image_info {
        border: 0.0313rem solid #bdbdbd;
        padding: .9375rem 1.1875rem 1.125rem 1.1875rem;

        .colorA8B565 {
          color: #A8B565;
        }

        .name {
          margin: .75rem 0;
        }
      }

      .footer_box {
        border: 0.0313rem solid #d9d9d9;
        height: 1.5625rem;
        border-top: none;

        .footer_info_box {
          padding-left: 1.25rem;
          position: relative;

          &:nth-child(1)::before {
            content: "";
            position: absolute;
            height: 0.7188rem;
            width: 0.0313rem;
            background: #e0e0e0;
            right: 0;
          }

          .info_icon {
            margin-right: 0.375rem;
            width: .9472rem;
            height: .7094rem;
          }

          &:nth-child(2) {
            .info_icon {
              margin-right: 0.375rem;
              width: .9472rem;
              height: .7188rem;
            }
          }
        }
      }


      &:hover {
        .no_show_btn {
          left: 0;
          transition: all 0.5s;
        }

        .fangz_logo {
          transform: scale(1.2);
          transition: all 0.8s;
        }
      }
    }
  }

  .pagination_box {
    margin-top: 1.875rem;
    width: 100%;

    .previous_btn,
    .net_btn {
      width: 2.4063rem;
      height: 0.9375rem;
      background: #232020;
      border-radius: 0.0625rem;
      border: none;
      color: #ffffff;
    }

    .number_btn {
      width: 0.9688rem;
      height: 0.9375rem;
      background: #232020;
      border-radius: 0.0625rem;
      border: none;
      color: #ffffff;
      margin-right: 0.4063rem;

      &:nth-child(2) {
        margin-left: 0.4063rem;
      }

      &.act {
        background: #fff;
        color: #000000;
      }
    }
  }

  .gengduo_box {
    margin-top: 1.9375rem;
    transition: all 1s;

    .gengduo_btn {
      width: 11.3125rem;
      height: 2.3438rem;
      border-radius: .0625rem;
      border: .0313rem solid #333333;

      &:hover {
        color: #fff;
        transition: all 2s;
        border-color: #fff;
      }
    }

  }
}

.cong_box {
  background: #292323;
  padding: 3.875rem 0;

  .container {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0;
    height: 26.9375rem;
    background-image: url("../../assets/images/home/cong_bj.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .cong_title {
      margin: 0 auto;
      position: absolute;
      top: 2.6563rem;
    }

    .cong_text_box {
      position: absolute;
      right: 8.6875rem;
      align-items: flex-end;

      .cong_text_image {
        width: 21.9688rem;
        margin-right: 2.6563rem;
        margin-bottom: 0.625rem;
      }

      .w170px_btn_h {
        border-color: #fff;
      }
    }

    .chong_bj_logo {
      width: 8.9063rem;
      position: absolute;
      left: 18.75rem;
      bottom: 0.5rem;
    }
  }
}

.type_box {
  padding: 0;

  .new_footer_box {
    position: relative;
    height: 19.6563rem;
    overflow: hidden;

    &:after {
      top: 0;
      position: absolute;
      content: " ";
      width: 100%;
      height: 100%;
      background: rgba(48, 48, 48, 0.7);
      transition: all 0.8s;
      z-index: 2;
    }

    &::before {
      top: 0;
      position: absolute;
      content: " ";
      width: 100%;
      height: 100%;
      background: rgba(48, 48, 48, 0.24);
      z-index: 1;
    }

    .title {
      width: 2.5rem;
      height: 0.7188rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 3;
    }
  }

  .new_footer_box:hover:after {
    top: -19.6563rem;
    transition: all 0.8s;
  }
}

.card_box {
  background: #a6b565;
  padding: 1.875rem 0;
  border-radius: 0;
}

@media (min-width: 1700px) and (max-width: 1800px) {

  .screen_btn,
  .search_btn {
    width: 5.0rem !important;
  }

  .house_list_item,
  .img_box,
  .house_logo {
    width: 14.0rem !important;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {

  .screen_btn,
  .search_btn {
    width: 5.4725rem !important;
  }

  .house_list_item,
  .img_box,
  .house_logo {
    width: 15.2938rem !important;
  }
}

@media (min-width: 1500px) and (max-width: 1600px) {

  .screen_btn,
  .search_btn {
    width: 5.7725rem !important;
  }

  .house_list_item,
  .img_box,
  .house_logo {
    width: 16.1rem !important;
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {

  .screen_btn,
  .search_btn {
    width: 6.1725rem !important;
  }

  .house_list_item,
  .img_box,
  .house_logo {
    width: 17.3rem !important;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {

  .screen_btn,
  .search_btn {
    width: 5.4925rem !important;
  }

  .house_list_item,
  .img_box,
  .house_logo {
    width: 15.4rem !important;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {

  .screen_btn,
  .search_btn {
    width: 6.05rem !important;
  }

  .house_list_item,
  .img_box,
  .house_logo {
    width: 16.85rem !important;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {

  .screen_btn,
  .search_btn {
    width: 5.5rem !important;
  }

  .house_list_item,
  .img_box,
  .house_logo {
    width: 15.4rem !important;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {

  .screen_btn,
  .search_btn {
    width: 6.00rem !important;
  }

  .house_list_item,
  .img_box,
  .house_logo {
    width: 16.75rem !important;
  }
}

// @media (min-width: 900px) and (max-width: 1000px) {
//   .screen_btn,.search_btn{
//     // width: .00rem !important;
//   }
//   .house_list_item,.img_box,.house_logo{
//     width: 16.75rem !important;
//   }
// }</style>
